<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import approvalModal from "../../../../common/modals/approvalModal";
import rejectionModal from "../../../../common/modals/rejectionModal";
import SideButtons from "@/components/side-buttons.vue";
import {
  fetchProductsUomsApi,
  fetchProductsCategoriesApi,
  fetchWarehousesApi,
  fetchEquipmentsApi,
  fetchProductsApi,
} from "@/api/common";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  components: { Multiselect, SideButtons, approvalModal, rejectionModal },
  import: {
    fetchProductsUomsApi,
    fetchProductsCategoriesApi,
    fetchWarehousesApi,
    fetchEquipmentsApi,
    fetchProductsApi,
  },
  props: {
    selectedProduct: null,
    opType: null,
  },
  data() {
    return {
      title: "Nouveau produit",
      uoms: [],
      categories: [],
      waerhouses: [],
      fromWaerhouses: [],
      toWaerhouses: [],
      contracts: [],
      products: [],
      equipments: [],
      select: false,
      transferTypes: [
        {
          id: "internal",
          name: "Interne",
        },
        {
          id: "external",
          name: "Externe",
        },
      ],

      transferableTypes: [
        {
          id: "equipment",
          name: "Equipement (Amortissable)",
        },
        {
          id: "product",
          name: "Produit (Consommable)",
        },
      ],
      periods: [
        { id: "days", name: "Jours" },
        { id: "months", name: "Mois" },
        { id: "years", name: "Années" },
      ],
      selectedPeriod: "",
      items: [
        {
          text: "Stock",
          href: "/",
        },
        {
          text: "Produits",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
      transferForm: {
        orderNo: "",
        transferDate: "",
        plannedDate: "",
        transferType: "",
        locationId: "",
        locationDestId: "",
        transferableType: "",
        externalLocationTxt: "", // entrée d'une source externe
        externalDestinationTxt: "", // sortie vers source xterne
        comments: "",
        equipments: [],
        products: [],
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled: false,
      up: false,
      getEmit: false,
      info: false,
      add: false,
      save: false,
      confirm: false,
      validate: false,
      edit: false,
      resetForm: false,
      SaveEdit: false,
      destroy: false,
      reset: false,
    };
  },
  validations: {
    transferForm: {
      transferDate: { required },
      transferType: { required },
      locationId: {
        required: requiredIf(function() {
          return (
            this.opType == "in" &&
            this.transferForm.transferType.id == "internal"
          );
        }),
      },
      locationDestId: {
        required: requiredIf(function() {
          return (
            this.opType == "out" &&
            this.transferForm.transferType.id == "internal"
          );
        }),
      },
      externalLocationTxt: {
        required: requiredIf(function() {
          return (
            this.opType == "in" &&
            this.transferForm.transferType.id == "external"
          );
        }),
      },
      externalDestinationTxt: {
        required: requiredIf(function() {
          return (
            this.opType == "out" &&
            this.transferForm.transferType.id == "external"
          );
        }),
      },
      transferableType: { required },
    },
  },
  created: function() {
    this.fetchWarehouses();
  },
  watch: {
    selectedProduct: {
      handler(val) {
        if (val) {
          this.transferForm.id = val.id;
          this.transferForm.uuid = val.uuid;
          this.transferForm.ref = val.ref;
          this.transferForm.orderNo = val.orderNo;
          this.transferForm.transferDate = val.op_date;
          this.transferForm.plannedDate = val.scheduled_date;
          this.transferForm.transferType =
            val.opType.plain == "in" ? val.in_ie : val.in_ie;
          this.transferForm.locationId = val.fromLocation;
          this.transferForm.locationDestId = val.destLocation;
          this.transferForm.transferableType = val.transferableType_obj;
          this.transferForm.externalLocationTxt = val.externalFromLocation;
          this.transferForm.externalDestinationTxt = val.isExternal.origin;
          this.transferForm.comments = val.comments;
          this.transferForm.opType = val.opType.plain;
          this.transferForm.statusPlain = val.status.plain;
          this.statusPlain = val.status.plain;
          this.transferForm.modelType = val.modelType;

          this._process_entries(val);

          this.select = true;
        } else this.resetData();
      },
    },
    add: {
      handler(val) {
        if (val) {
          // console.log("clearForm");
          this.disabled = true;
          this.resetData();
          this.resetForm = false;
        }
      },
    },
    save: {
      handler(val) {
        if (val) {
          // console.log("Save Form");
          this.formSubmit();
          // this.save=false;
        } else console.log("save false");
      },
    },
    edit: {
      handler(val) {
        if (val) {
          if (this.statusPlain == "draft") {
            this.disabled = true;
            this.resetForm = false;
          } else {
            this.$toast.error("Vous ne pouvez pas modifier ce transfer");
          }
        }
      },
    },
    SaveEdit: {
      handler(val) {
        if (val) {
          // console.log(val);
          this.updateProduct();
        }
      },
    },
    resetForm: {
      handler(val) {
        if (val) {
          console.log("reset");
          this.resetData();
          this.disabled = false;
          this.submitted = false;
          this.select = false;
        }
      },
    },
    destroy: {
      handler(val) {
        if (val) {
          if (this.statusPlain == "draft") {
            this.deleteProduct(this.transferForm.ref, this.transferForm.uuid);
          } else {
            this.$toast.error("Vous ne pouvez pas supprimer ce transfer");
          }
        }
      },
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchEquipments();
  },
  methods: {
    formatAmount(val, currency = "DZD") {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: currency,
      }).format(val);
    },
    onCategorySelect(){
      this.transferForm.equipments = [];
      this.transferForm.products = [];
    },

    _process_entries(rec) {
      this.transferForm.equipments = [];
      this.transferForm.products = [];
      var entries = rec.entries;
      entries.forEach((singleEntry) => {
        if (rec.transferableType == "equipment") {
          this.transferForm.equipments.push({
            equipment: singleEntry.item,
            qty: singleEntry.qty,
            realQty: singleEntry.done_qty,
            doneQty: singleEntry.done_qty,
            unitPrice: singleEntry.unit_price,
            inventoryNo: singleEntry.inventory_no,
            location: singleEntry.location,
            done: false,
          });
        } else if (rec.transferableType == "product") {
          this.transferForm.products.push({
            product: singleEntry.item,
            qty: singleEntry.qty,
            realQty: singleEntry.done_qty,
            doneQty: singleEntry.done_qty,
            unitPrice: singleEntry.unit_price,
            inventoryNo: singleEntry.inventory_no,
            location: singleEntry.location,
            done: false,
          });
        }
      });
    },
    fetchProducts() {
      fetchProductsApi()
        .then((res) => {
          this.products = res.data.data;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchEquipments() {
      fetchEquipmentsApi()
        .then((res) => {
          this.equipments = res.data.original.list;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    _validateCurrentData() {
      return true;
    },
    _add_new_equipment() {
      this.transferForm.equipments.push({
        equipment: "",
        location: "",
        unitPrice: 0,
        inventoryNo: "",
        qty: "",
        realQty: "",
        doneQty: "",
        diff: 0,
      });
    },
    _add_new_product() {
      this.transferForm.products.push({
        product: "",
        qty: "",
        location: "",
        realQty: "",
        doneQty: "",
        unitPrice: 0,
        inventoryNo: "",
        diff: 0,
      });
    },
    AddformData() {
      switch (this.transferForm.transferableType.id) {
        case "equipment":
          this._add_new_equipment();
          break;

        case "product":
          this._add_new_product();
          break;

        default:
          this.$toast.error("<i class='fas fa-sad-tear'></i>");
          break;
      }
    },
    fetchWarehouses() {
      fetchWarehousesApi()
        .then((res) => {
          this.fromWaerhouses = res.data.original.data;
          this.toWaerhouses = res.data.original.data;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    idPeriod(period) {
      this.transferForm.lifetime_period = period.id;
    },

    _check_items() {
      var transferableType = this.transferForm.transferableType.id;
      var checked = true;
      switch (transferableType) {
        case "equipment":
          if (this.transferForm.equipments.length == 0) {
            checked = false;
            this.$toast.error("Vous devez sélectionner au moin un équipement.");
          }
          break;

        case "product":
          if (this.transferForm.products.length == 0) {
            checked = false;
            this.$toast.error("Vous devez sélectionner au moin un produit.");
          }
          break;

        default:
          break;
      }

      return checked;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.transferForm.$invalid && this._check_items()) {
        this.$http
          .post("/ops/stock_moves/store", {
            data: this.transferForm,
            opType: this.opType,
          })
          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.$v.$reset();
                this.resetData();
                this.$emit("refreshStockMovesTable", true);
                this.save = false;
                this.disabled = false;
                this.edit = false;

                break;

              case 202:
                this.$toast.warning(res.data.original.msg);
                this.$v.$reset();
                this.resetData();
                this.$emit("refresh", true);
                this.save = false;
                this.disabled = false;
                this.edit = false;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    resetData() {
      this.transferForm.uuid = "";
      this.transferForm.orderNo = "";
      this.transferForm.transferDate = "";
      this.transferForm.plannedDate = "";
      this.transferForm.transferType = "";
      this.transferForm.locationId = "";
      this.transferForm.locationDestId = "";
      this.transferForm.transferableType = "";
      this.transferForm.comments = "";
      this.transferForm.statusPlain = "";
    },
    deleteProduct(deptName, deptUid) {
      var _this = this;
      var loader = this.$loading.show();
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le transfer N°: " + deptName + "?",
        text: "Cette Action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: '<i class="fas fa-trash"></i> Supprimer!',
        cancelButtonText: '<i class="far fa-times-circle"></i> Annuler!',
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/stock_moves/delete", {
              transferId: deptUid,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$v.$reset();
                  this.resetData();
                  this.$emit("refreshStockMovesTable", true);
                  this.save = false;
                  this.disabled = false;
                  break;

                case 500:
                  loader.hide();
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              _this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    deleteRow(index) {
      if (this.transferForm.transferableType.id == "equipment") {
        Swal.fire({
          title: "Êtes-vous sûr de supprimer cette line ?",
          text: "Cette Action est irréversible",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f46a6a",
          cancelButtonColor: "#34c38f",
          confirmButtonText: '<i class="fas fa-trash"></i> Supprimer!',
          cancelButtonText: '<i class="far fa-times-circle"></i> Annuler!',
        }).then((result) => {
          if (result.value) {
            this.transferForm.equipments.splice(index, 1);
          }
        });
      } else if (this.transferForm.transferableType.id == "product") {
        Swal.fire({
          title: "Êtes-vous sûr de supprimer cette line ?",
          text: "Cette Action est irréversible",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f46a6a",
          cancelButtonColor: "#34c38f",
          confirmButtonText: '<i class="fas fa-trash"></i> Supprimer!',
          cancelButtonText: '<i class="far fa-times-circle"></i> Annuler!',
        }).then((result) => {
          if (result.value) {
            this.transferForm.products.splice(index, 1);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <SideButtons
      :reset="resetForm"
      :select="select"
      @info="info = $event"
      @add="add = $event"
      @save="save = $event"
      @edit="edit = $event"
      @resetEmit="resetForm = $event"
      @SaveEdit="SaveEdit = $event"
      @delete="destroy = $event"
    />
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <template v-if="transferForm.uuid">
            <div
              class="row singleBookingActions"
              v-if="transferForm.statusPlain == 'draft'"
            >
              <button class="btn btn-primary" v-b-modal.approvalModal>
                <i class="fas fa-check"></i> Approuver
              </button>
              <button class="btn btn-danger" v-b-modal.rejectionModal>
                <i class="fas fa-window-close"></i> Rejeter
              </button>
            </div>
          </template>
          <fieldset :disabled="!disabled">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"
                      >Infos Générales</span
                    >
                  </template>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="deptName">Date de transfert *</label>
                        <input
                          id="deptName"
                          type="date"
                          class="form-control"
                          placeholder=""
                          v-model="transferForm.transferDate"
                          value=""
                          :class="{
                            'is-invalid':
                              submitted && $v.transferForm.transferDate.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitted && $v.transferForm.transferDate.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.transferForm.transferDate.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="deptName">Date de Planifiée</label>
                        <input
                          id="deptName"
                          type="date"
                          class="form-control"
                          placeholder=""
                          value=""
                          v-model="transferForm.plannedDate"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="validationCustom02"
                          >Type de transfert</label
                        >
                        <multiselect
                          v-model="transferForm.transferType"
                          :select-label="''"
                          class="multiselect"
                          :disabled="!disabled"
                          :class="{
                            disabledMS: !disabled,
                            'is-invalid':
                              submitted && $v.transferForm.transferType.$error,
                          }"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="transferTypes"
                          placeholder="Type de transfert"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleUom"
                            slot-scope="{ transferType }"
                            >{{ transferType.name }}</template
                          >
                        </multiselect>
                        <div
                          v-if="
                            submitted && $v.transferForm.transferType.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.transferForm.transferType.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="validationCustom02">Catégorie</label>
                        <multiselect
                          v-model="transferForm.transferableType"
                          :select-label="''"
                          class="multiselect"
                          :disabled="!disabled"
                          :class="{
                            disabledMS: !disabled,
                            'is-invalid':
                              submitted &&
                              $v.transferForm.transferableType.$error,
                          }"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="transferableTypes"
                          @select="onCategorySelect"
                          placeholder="Catégorie"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleUom"
                            slot-scope="{ transferableType }"
                            >{{ transferableType.name }}</template
                          >
                        </multiselect>
                        <div
                          v-if="
                            submitted && $v.transferForm.transferableType.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.transferForm.transferableType.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="">N° commande</label>
                        <input
                          id="deptName"
                          type="text"
                          class="form-control"
                          placeholder=""
                          value=""
                          v-model="transferForm.orderNo"
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="validationCustom02">De</label>
                        <multiselect
                          v-if="
                            (transferForm.transferType.id != 'external' &&
                              opType == 'in') ||
                              opType == 'out'
                          "
                          v-model="transferForm.locationId"
                          :select-label="''"
                          class="multiselect"
                          :disabled="!disabled"
                          :class="{
                            disabledMS: !disabled,
                            'is-invalid':
                              submitted && $v.transferForm.locationId.$error,
                          }"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="fromWaerhouses"
                          placeholder="De"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleUom"
                            slot-scope="{ transferType }"
                            >{{ transferType.name }}</template
                          >
                        </multiselect>
                        <input
                          v-else
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.transferForm.externalLocationTxt.$error,
                          }"
                          placeholder=""
                          value=""
                          v-model="transferForm.externalLocationTxt"
                        />
                        <div
                          v-if="
                            submitted &&
                              ($v.transferForm.locationId.$error ||
                                $v.transferForm.externalLocationTxt.$error)
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.transferForm.locationId.required"
                            >Champ obligatoire.</span
                          >
                          <span
                            v-if="!$v.transferForm.externalLocationTxt.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="validationCustom02">à</label>
                        <multiselect
                          v-if="
                            opType == 'in' ||
                              (opType == 'out' &&
                                transferForm.transferType.id != 'external')
                          "
                          v-model="transferForm.locationDestId"
                          :select-label="''"
                          class="multiselect"
                          :disabled="!disabled"
                          :class="{
                            disabledMS: !disabled,
                            'is-invalid':
                              submitted &&
                              $v.transferForm.locationDestId.$error,
                          }"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="toWaerhouses"
                          placeholder="à"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleUom"
                            slot-scope="{ transferableType }"
                            >{{ transferableType.name }}</template
                          >
                        </multiselect>

                        <input
                          v-else
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.transferForm.externalDestinationTxt.$error,
                          }"
                          placeholder=""
                          value=""
                          v-model="transferForm.externalDestinationTxt"
                        />
                        <div
                          v-if="
                            submitted &&
                              ($v.transferForm.locationDestId.$error ||
                                $v.transferForm.externalDestinationTxt.$error)
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.transferForm.locationDestId.required"
                            >Champ obligatoire.</span
                          >
                          <span
                            v-if="
                              !$v.transferForm.externalDestinationTxt.required
                            "
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="validationCustom02">Description</label>
                        <textarea
                          class="form-control"
                          v-model="transferForm.comments"
                          id=""
                          cols="30"
                          rows="1"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab v-if="transferForm.transferableType">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">{{
                      transferForm.transferableType.name
                    }}</span>
                  </template>
                  <div
                    class="table-responsive"
                    v-if="transferForm.transferableType.id == 'equipment'"
                  >
                    <table class="table mb-0">
                      <thead class="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Equipement</th>
                          <th>Stockage</th>
                          <th>N° inventaire</th>
                          <th>Prix unitaire</th>
                          <th>Qté commandé</th>
                          <th>Qté Livrée</th>
                          <th>Ecart</th>
                          <!-- <th>Qté reçu</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(equipment, index) in transferForm.equipments"
                          :key="equipment.id"
                        >
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ equipment.equipment.name }}</td>
                          <td>{{ equipment.location.name }}</td>
                          <td>{{ equipment.inventoryNo }}</td>
                          <td>{{ formatAmount(equipment.unitPrice) }}</td>
                          <td>{{ equipment.qty }}</td>
                          <td>{{ equipment.doneQty }}</td>
                          <td>{{ Math.abs(equipment.qty -  equipment.doneQty) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div v-if="transferForm.transferableType.id == 'equipment'">
                    <div
                      class="row"
                      v-for="(equipment, index) in transferForm.equipments"
                      :key="equipment.id"
                    >
                      <div class="form-group col-lg-2">
                        <label for="message">Equipement</label>
                        <multiselect
                          v-model="equipment.equipment"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="equipments"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="message">Stockage</label>
                        <multiselect
                          v-model="equipment.location"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="toWaerhouses"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">N° inventaire</label>
                        <input
                          id="subject"
                          v-model="equipment.inventoryNo"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-1">
                        <label for="resume">Prix unitaire</label>
                        <input
                          id="subject"
                          v-model="equipment.unitPrice"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">Qté commandé</label>
                        <input
                          id="subject"
                          v-model="equipment.qty"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">Qté Livrée</label>
                        <input
                          id="subject"
                          v-model="equipment.doneQty"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-1">
                        <label for="resume">Ecart</label>
                        <input
                          id="subject"
                          
                          :value="Math.abs(equipment.qty - equipment.doneQty)"
                          readonly
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="col-lg-1 align-self-center">
                        <a
                          v-if="
                            transferForm.equipments.length > 1 && index != 0
                          "
                          href="javascript:void(0)"
                          ><i
                            class="bx bx-trash-alt"
                            @click="deleteRow(index)"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <input
                      type="button"
                      class="btn btn-success mt-3 mt-lg-0"
                      value="Ajouter"
                      @click="AddformData"
                    />
                  </div>

                  <div
                    class="table-responsive"
                    v-if="transferForm.transferableType.id == 'product'"
                  >
                    <table class="table mb-0">
                      <thead class="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Produit</th>
                          <th>Stockage</th>
                          <th>Code barre</th>
                          <th>Prix unitaire</th>
                          <th>Qté commandée</th>
                          <th>Qté reçu</th>
                          <th>Ecart</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(product, index) in transferForm.products"
                          :key="product.id"
                        >
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ product.product.name }}</td>
                          <td>{{ product.location.name }}</td>
                          <td>{{ product.inventoryNo }}</td>
                          <td>{{ formatAmount(product.unitPrice) }}</td>
                          <td>{{ product.qty }}</td>
                          <td>{{ product.realQty }}</td>
                          <td>{{ Math.abs(product.qty - product.realQty) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div v-if="transferForm.transferableType.id == 'product'">
                    <div
                      class="row"
                      v-for="(product, index) in transferForm.products"
                      :key="product.id"
                    >
                      <div class="form-group col-lg-2">
                        <label for="message">Produit</label>
                        <multiselect
                          v-model="product.product"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="products"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="message">Stockage</label>
                        <multiselect
                          v-model="product.location"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="toWaerhouses"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">Code barre</label>
                        <input
                          id="subject"
                          v-model="product.inventoryNo"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-1">
                        <label for="resume">Prix Unitaire</label>
                        <input
                          id="subject"
                          v-model="product.unitPrice"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">Qté commandée</label>
                        <input
                          id="subject"
                          v-model="product.qty"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-lg-2">
                        <label for="resume">Qté livrée</label>
                        <input
                          id="subject"
                          v-model="product.realQty"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-lg-1">
                        <label for="resume">Ecart</label>
                        <input
                          id="subject"
                          :value="Math.abs(product.qty - product.realQty)"
                          readonly
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="col-lg-1 align-self-center">
                        <a
                          v-if="transferForm.products.length > 1 && index != 0"
                          href="javascript:void(0)"
                          ><i
                            class="bx bx-trash-alt"
                            @click="deleteRow(index)"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <input
                      type="button"
                      class="btn btn-success mt-3 mt-lg-0"
                      value="Ajouter"
                      @click="AddformData"
                    />
                  </div>
                </b-tab>

                <approval-modal
                  :modelRef="transferForm.ref"
                  :modelId="transferForm.id"
                  :modelType="transferForm.modelType"
                />
                <rejection-modal
                  :modelRef="transferForm.ref"
                  :modelId="transferForm.id"
                  :modelType="transferForm.modelType"
                />
              </b-tabs>

              <div class="row mt-3">
                <div class="col text-right">
                  <b-button
                    v-if="add"
                    @click="formSubmit"
                    variant="primary"
                    class="btn-label mr-2"
                  >
                    <i class="far fa-save text-white label-icon"></i> Enregitrer
                  </b-button>
                  <b-button
                    v-if="edit"
                    @click="formSubmit"
                    variant="primary"
                    class="btn-label mr-2"
                  >
                    <i class="far fa-save text-white label-icon"></i> Enregitrer
                  </b-button>

                  <b-button
                    v-if="add || edit"
                    @click="resetForm = true"
                    variant="danger"
                    class="btn-label mr-2"
                  >
                    <i class="fas fa-times-circle text-white label-icon"></i>
                    Annuler
                  </b-button>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>
