var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("SideButtons", {
        attrs: { reset: _vm.resetForm, select: _vm.select },
        on: {
          info: function($event) {
            _vm.info = $event
          },
          add: function($event) {
            _vm.add = $event
          },
          save: function($event) {
            _vm.save = $event
          },
          edit: function($event) {
            _vm.edit = $event
          },
          resetEmit: function($event) {
            _vm.resetForm = $event
          },
          SaveEdit: function($event) {
            _vm.SaveEdit = $event
          },
          delete: function($event) {
            _vm.destroy = $event
          }
        }
      }),
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.transferForm.uuid
                ? [
                    _vm.transferForm.statusPlain == "draft"
                      ? _c("div", { staticClass: "row singleBookingActions" }, [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.approvalModal",
                                  modifiers: { approvalModal: true }
                                }
                              ],
                              staticClass: "btn btn-primary"
                            },
                            [
                              _c("i", { staticClass: "fas fa-check" }),
                              _vm._v(" Approuver ")
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.rejectionModal",
                                  modifiers: { rejectionModal: true }
                                }
                              ],
                              staticClass: "btn btn-danger"
                            },
                            [
                              _c("i", { staticClass: "fas fa-window-close" }),
                              _vm._v(" Rejeter ")
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _c("fieldset", { attrs: { disabled: !_vm.disabled } }, [
                _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.formSubmit($event)
                      }
                    }
                  },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "p-3 text-muted" } },
                      [
                        _c(
                          "b-tab",
                          {
                            staticClass: "border-0",
                            attrs: { active: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block d-sm-none"
                                      },
                                      [_c("i", { staticClass: "fas fa-home" })]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-none d-sm-inline-block"
                                      },
                                      [_vm._v("Infos Générales")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-3" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "deptName" } }, [
                                    _vm._v("Date de transfert *")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.transferForm.transferDate,
                                        expression: "transferForm.transferDate"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.submitted &&
                                        _vm.$v.transferForm.transferDate.$error
                                    },
                                    attrs: {
                                      id: "deptName",
                                      type: "date",
                                      placeholder: "",
                                      value: ""
                                    },
                                    domProps: {
                                      value: _vm.transferForm.transferDate
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.transferForm,
                                          "transferDate",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.submitted &&
                                  _vm.$v.transferForm.transferDate.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          !_vm.$v.transferForm.transferDate
                                            .required
                                            ? _c("span", [
                                                _vm._v("Champ obligatoire.")
                                              ])
                                            : _vm._e(),
                                          _c("br")
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "deptName" } }, [
                                    _vm._v("Date de Planifiée")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.transferForm.plannedDate,
                                        expression: "transferForm.plannedDate"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "deptName",
                                      type: "date",
                                      placeholder: "",
                                      value: ""
                                    },
                                    domProps: {
                                      value: _vm.transferForm.plannedDate
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.transferForm,
                                          "plannedDate",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "validationCustom02" } },
                                      [_vm._v("Type de transfert")]
                                    ),
                                    _c("multiselect", {
                                      staticClass: "multiselect",
                                      class: {
                                        disabledMS: !_vm.disabled,
                                        "is-invalid":
                                          _vm.submitted &&
                                          _vm.$v.transferForm.transferType
                                            .$error
                                      },
                                      attrs: {
                                        "select-label": "",
                                        disabled: !_vm.disabled,
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.transferTypes,
                                        placeholder: "Type de transfert",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "singleUom",
                                          fn: function(ref) {
                                            var transferType = ref.transferType
                                            return [
                                              _vm._v(_vm._s(transferType.name))
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.transferForm.transferType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.transferForm,
                                            "transferType",
                                            $$v
                                          )
                                        },
                                        expression: "transferForm.transferType"
                                      }
                                    }),
                                    _vm.submitted &&
                                    _vm.$v.transferForm.transferType.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            !_vm.$v.transferForm.transferType
                                              .required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            _c("br")
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "validationCustom02" } },
                                      [_vm._v("Catégorie")]
                                    ),
                                    _c("multiselect", {
                                      staticClass: "multiselect",
                                      class: {
                                        disabledMS: !_vm.disabled,
                                        "is-invalid":
                                          _vm.submitted &&
                                          _vm.$v.transferForm.transferableType
                                            .$error
                                      },
                                      attrs: {
                                        "select-label": "",
                                        disabled: !_vm.disabled,
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.transferableTypes,
                                        placeholder: "Catégorie",
                                        "allow-empty": false
                                      },
                                      on: { select: _vm.onCategorySelect },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "singleUom",
                                          fn: function(ref) {
                                            var transferableType =
                                              ref.transferableType
                                            return [
                                              _vm._v(
                                                _vm._s(transferableType.name)
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value:
                                          _vm.transferForm.transferableType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.transferForm,
                                            "transferableType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "transferForm.transferableType"
                                      }
                                    }),
                                    _vm.submitted &&
                                    _vm.$v.transferForm.transferableType.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            !_vm.$v.transferForm
                                              .transferableType.required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            _c("br")
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("N° commande")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.transferForm.orderNo,
                                        expression: "transferForm.orderNo"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "deptName",
                                      type: "text",
                                      placeholder: "",
                                      value: ""
                                    },
                                    domProps: {
                                      value: _vm.transferForm.orderNo
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.transferForm,
                                          "orderNo",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "validationCustom02" } },
                                      [_vm._v("De")]
                                    ),
                                    (_vm.transferForm.transferType.id !=
                                      "external" &&
                                      _vm.opType == "in") ||
                                    _vm.opType == "out"
                                      ? _c("multiselect", {
                                          staticClass: "multiselect",
                                          class: {
                                            disabledMS: !_vm.disabled,
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.transferForm.locationId
                                                .$error
                                          },
                                          attrs: {
                                            "select-label": "",
                                            disabled: !_vm.disabled,
                                            searchable: true,
                                            "track-by": "id",
                                            label: "name",
                                            options: _vm.fromWaerhouses,
                                            placeholder: "De",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "singleUom",
                                                fn: function(ref) {
                                                  var transferType =
                                                    ref.transferType
                                                  return [
                                                    _vm._v(
                                                      _vm._s(transferType.name)
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3590487262
                                          ),
                                          model: {
                                            value: _vm.transferForm.locationId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.transferForm,
                                                "locationId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transferForm.locationId"
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.transferForm
                                                  .externalLocationTxt,
                                              expression:
                                                "transferForm.externalLocationTxt"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.transferForm
                                                .externalLocationTxt.$error
                                          },
                                          attrs: {
                                            type: "text",
                                            placeholder: "",
                                            value: ""
                                          },
                                          domProps: {
                                            value:
                                              _vm.transferForm
                                                .externalLocationTxt
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.transferForm,
                                                "externalLocationTxt",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                    _vm.submitted &&
                                    (_vm.$v.transferForm.locationId.$error ||
                                      _vm.$v.transferForm.externalLocationTxt
                                        .$error)
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            !_vm.$v.transferForm.locationId
                                              .required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            !_vm.$v.transferForm
                                              .externalLocationTxt.required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            _c("br")
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "validationCustom02" } },
                                      [_vm._v("à")]
                                    ),
                                    _vm.opType == "in" ||
                                    (_vm.opType == "out" &&
                                      _vm.transferForm.transferType.id !=
                                        "external")
                                      ? _c("multiselect", {
                                          staticClass: "multiselect",
                                          class: {
                                            disabledMS: !_vm.disabled,
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.transferForm.locationDestId
                                                .$error
                                          },
                                          attrs: {
                                            "select-label": "",
                                            disabled: !_vm.disabled,
                                            searchable: true,
                                            "track-by": "id",
                                            label: "name",
                                            options: _vm.toWaerhouses,
                                            placeholder: "à",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "singleUom",
                                                fn: function(ref) {
                                                  var transferableType =
                                                    ref.transferableType
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        transferableType.name
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1622511262
                                          ),
                                          model: {
                                            value:
                                              _vm.transferForm.locationDestId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.transferForm,
                                                "locationDestId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transferForm.locationDestId"
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.transferForm
                                                  .externalDestinationTxt,
                                              expression:
                                                "transferForm.externalDestinationTxt"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.transferForm
                                                .externalDestinationTxt.$error
                                          },
                                          attrs: {
                                            type: "text",
                                            placeholder: "",
                                            value: ""
                                          },
                                          domProps: {
                                            value:
                                              _vm.transferForm
                                                .externalDestinationTxt
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.transferForm,
                                                "externalDestinationTxt",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                    _vm.submitted &&
                                    (_vm.$v.transferForm.locationDestId
                                      .$error ||
                                      _vm.$v.transferForm.externalDestinationTxt
                                        .$error)
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            !_vm.$v.transferForm.locationDestId
                                              .required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            !_vm.$v.transferForm
                                              .externalDestinationTxt.required
                                              ? _c("span", [
                                                  _vm._v("Champ obligatoire.")
                                                ])
                                              : _vm._e(),
                                            _c("br")
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "validationCustom02" } },
                                    [_vm._v("Description")]
                                  ),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.transferForm.comments,
                                        expression: "transferForm.comments"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "", cols: "30", rows: "1" },
                                    domProps: {
                                      value: _vm.transferForm.comments
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.transferForm,
                                          "comments",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]
                        ),
                        _vm.transferForm.transferableType
                          ? _c(
                              "b-tab",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-inline-block d-sm-none"
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "far fa-user"
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-none d-sm-inline-block"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.transferForm
                                                    .transferableType.name
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2359806595
                                )
                              },
                              [
                                _vm.transferForm.transferableType.id ==
                                "equipment"
                                  ? _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          { staticClass: "table mb-0" },
                                          [
                                            _c(
                                              "thead",
                                              { staticClass: "thead-light" },
                                              [
                                                _c("tr", [
                                                  _c("th", [_vm._v("ID")]),
                                                  _c("th", [
                                                    _vm._v("Equipement")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Stockage")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("N° inventaire")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Prix unitaire")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Qté commandé")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Qté Livrée")
                                                  ]),
                                                  _c("th", [_vm._v("Ecart")])
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.transferForm.equipments,
                                                function(equipment, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: equipment.id },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "row"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(index + 1)
                                                          )
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            equipment.equipment
                                                              .name
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            equipment.location
                                                              .name
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            equipment.inventoryNo
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatAmount(
                                                              equipment.unitPrice
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(equipment.qty)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            equipment.doneQty
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            Math.abs(
                                                              equipment.qty -
                                                                equipment.doneQty
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.transferForm.transferableType.id ==
                                "equipment"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.transferForm.equipments,
                                          function(equipment, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: equipment.id,
                                                staticClass: "row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "message"
                                                        }
                                                      },
                                                      [_vm._v("Equipement")]
                                                    ),
                                                    _c("multiselect", {
                                                      attrs: {
                                                        searchable: true,
                                                        "track-by": "id",
                                                        label: "name",
                                                        options: _vm.equipments,
                                                        placeholder:
                                                          "Selectionner",
                                                        "allow-empty": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "singleMealType",
                                                            fn: function(ref) {
                                                              var type =
                                                                ref.type
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    type.name
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          equipment.equipment,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            equipment,
                                                            "equipment",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "equipment.equipment"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "message"
                                                        }
                                                      },
                                                      [_vm._v("Stockage")]
                                                    ),
                                                    _c("multiselect", {
                                                      attrs: {
                                                        searchable: true,
                                                        "track-by": "id",
                                                        label: "name",
                                                        options:
                                                          _vm.toWaerhouses,
                                                        placeholder:
                                                          "Selectionner",
                                                        "allow-empty": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "singleMealType",
                                                            fn: function(ref) {
                                                              var type =
                                                                ref.type
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    type.name
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          equipment.location,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            equipment,
                                                            "location",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "equipment.location"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("N° inventaire")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            equipment.inventoryNo,
                                                          expression:
                                                            "equipment.inventoryNo"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value:
                                                          equipment.inventoryNo
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            equipment,
                                                            "inventoryNo",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-1"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Prix unitaire")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            equipment.unitPrice,
                                                          expression:
                                                            "equipment.unitPrice"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value:
                                                          equipment.unitPrice
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            equipment,
                                                            "unitPrice",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Qté commandé")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: equipment.qty,
                                                          expression:
                                                            "equipment.qty"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: equipment.qty
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            equipment,
                                                            "qty",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Qté Livrée")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            equipment.doneQty,
                                                          expression:
                                                            "equipment.doneQty"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: equipment.doneQty
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            equipment,
                                                            "doneQty",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-1"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Ecart")]
                                                    ),
                                                    _c("input", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        readonly: "",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: Math.abs(
                                                          equipment.qty -
                                                            equipment.doneQty
                                                        )
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-lg-1 align-self-center"
                                                  },
                                                  [
                                                    _vm.transferForm.equipments
                                                      .length > 1 && index != 0
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-trash-alt",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteRow(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _c("input", {
                                          staticClass:
                                            "btn btn-success mt-3 mt-lg-0",
                                          attrs: {
                                            type: "button",
                                            value: "Ajouter"
                                          },
                                          on: { click: _vm.AddformData }
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.transferForm.transferableType.id ==
                                "product"
                                  ? _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          { staticClass: "table mb-0" },
                                          [
                                            _c(
                                              "thead",
                                              { staticClass: "thead-light" },
                                              [
                                                _c("tr", [
                                                  _c("th", [_vm._v("ID")]),
                                                  _c("th", [_vm._v("Produit")]),
                                                  _c("th", [
                                                    _vm._v("Stockage")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Code barre")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Prix unitaire")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Qté commandée")
                                                  ]),
                                                  _c("th", [
                                                    _vm._v("Qté reçu")
                                                  ]),
                                                  _c("th", [_vm._v("Ecart")])
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.transferForm.products,
                                                function(product, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: product.id },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "row"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(index + 1)
                                                          )
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.product.name
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.location
                                                              .name
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.inventoryNo
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatAmount(
                                                              product.unitPrice
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(product.qty)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.realQty
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            Math.abs(
                                                              product.qty -
                                                                product.realQty
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.transferForm.transferableType.id ==
                                "product"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.transferForm.products,
                                          function(product, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: product.id,
                                                staticClass: "row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "message"
                                                        }
                                                      },
                                                      [_vm._v("Produit")]
                                                    ),
                                                    _c("multiselect", {
                                                      attrs: {
                                                        searchable: true,
                                                        "track-by": "id",
                                                        label: "name",
                                                        options: _vm.products,
                                                        placeholder:
                                                          "Selectionner",
                                                        "allow-empty": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "singleMealType",
                                                            fn: function(ref) {
                                                              var type =
                                                                ref.type
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    type.name
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value: product.product,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            product,
                                                            "product",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.product"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "message"
                                                        }
                                                      },
                                                      [_vm._v("Stockage")]
                                                    ),
                                                    _c("multiselect", {
                                                      attrs: {
                                                        searchable: true,
                                                        "track-by": "id",
                                                        label: "name",
                                                        options:
                                                          _vm.toWaerhouses,
                                                        placeholder:
                                                          "Selectionner",
                                                        "allow-empty": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "singleMealType",
                                                            fn: function(ref) {
                                                              var type =
                                                                ref.type
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    type.name
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value: product.location,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            product,
                                                            "location",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.location"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Code barre")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            product.inventoryNo,
                                                          expression:
                                                            "product.inventoryNo"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value:
                                                          product.inventoryNo
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            product,
                                                            "inventoryNo",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-1"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Prix Unitaire")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            product.unitPrice,
                                                          expression:
                                                            "product.unitPrice"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: product.unitPrice
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            product,
                                                            "unitPrice",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Qté commandée")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: product.qty,
                                                          expression:
                                                            "product.qty"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: product.qty
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            product,
                                                            "qty",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-2"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Qté livrée")]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            product.realQty,
                                                          expression:
                                                            "product.realQty"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: product.realQty
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            product,
                                                            "realQty",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-lg-1"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "resume" }
                                                      },
                                                      [_vm._v("Ecart")]
                                                    ),
                                                    _c("input", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "subject",
                                                        readonly: "",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: Math.abs(
                                                          product.qty -
                                                            product.realQty
                                                        )
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-lg-1 align-self-center"
                                                  },
                                                  [
                                                    _vm.transferForm.products
                                                      .length > 1 && index != 0
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bx bx-trash-alt",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteRow(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _c("input", {
                                          staticClass:
                                            "btn btn-success mt-3 mt-lg-0",
                                          attrs: {
                                            type: "button",
                                            value: "Ajouter"
                                          },
                                          on: { click: _vm.AddformData }
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("approval-modal", {
                          attrs: {
                            modelRef: _vm.transferForm.ref,
                            modelId: _vm.transferForm.id,
                            modelType: _vm.transferForm.modelType
                          }
                        }),
                        _c("rejection-modal", {
                          attrs: {
                            modelRef: _vm.transferForm.ref,
                            modelId: _vm.transferForm.id,
                            modelType: _vm.transferForm.modelType
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c(
                        "div",
                        { staticClass: "col text-right" },
                        [
                          _vm.add
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn-label mr-2",
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.formSubmit }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "far fa-save text-white label-icon"
                                  }),
                                  _vm._v(" Enregitrer ")
                                ]
                              )
                            : _vm._e(),
                          _vm.edit
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn-label mr-2",
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.formSubmit }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "far fa-save text-white label-icon"
                                  }),
                                  _vm._v(" Enregitrer ")
                                ]
                              )
                            : _vm._e(),
                          _vm.add || _vm.edit
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn-label mr-2",
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function($event) {
                                      _vm.resetForm = true
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-times-circle text-white label-icon"
                                  }),
                                  _vm._v(" Annuler ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }