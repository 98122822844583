<script>
import Swal from "sweetalert2";
import { fetchProductsApi } from "@/api/common";
import productForm from "./productForm.vue";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

export default {
  import: {fetchProductsApi},
  components:{
    productForm,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
  },
  props: {
    opType: null,
    tableTitle: null,
  },
  data() {
    return {
      showModal: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      tableData: [],
      stockMoves: [],
      title: "Produits",
      items: [
        {
          text: "Master Data",
          to: {name:"master_data"}
        },
        {
          text: "Referentiels",
          to: {name:"master_data.referentiels"}
        },
        {
          text: "Produits",
          active: true
        }
      ],
      active:false,
      uoms: [],
      resetVals:false,
      totalPages: 1,
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      values:undefined,
      selectedRows:[],
      selectedIndex:-1,
      up:undefined,
      cols: [
        {
          name: 'Référence',
          field: 'ref',
          sort: ''
        },
        {
          name: 'Créé Par',
          field: 'by',
          sort: ''
        },
        {
          name: 'Date d\'opération',
          field: 'designation',
          sort: ''
        },
        {
          name: 'De',
          field: 'from',
          sort: ''
        },
        {
          name: 'à',
          field: 'to',
          sort: ''
        },
        {
          name: 'Statut',
          field: 'status',
          sort: ''
        },
      ],
    };
  },

  mounted() {
    this.fetchStockMovesList();
  },

  watch:{
    up:{
      handler(val){
        if(val) {
          this.fetchStockMovesList();
          this.up = false;
        }
      }
    }
  },
  methods: {

    fetchStockMovesList(){
      var loader = this.$loading.show();
      this.$http
            .post("/ops/stock_moves/list", { opType: this.opType })
            .then((res) => {
              var status = res.data.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.stockMoves = res.data.list;
                  break;

                case 500:
                  this.$toast.warning(res.data.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {
              loader.hide();
            });
    },

     deleteProduct(deptName, deptUid) {
       var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le produit: " + deptName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/stock/products/delete/" + deptUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  _this.$toast.success(res.data.original.msg);
                  _this.fetchProdusList();
                  break;

                case 500:
                  _this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row,index){
      if(this.selectedRows.length > 0) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  
};
</script>

<template>
  <div class="row">
      <div class="col-lg-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des {{ tableTitle }}</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class=" mb-0 shadow">
                <dataset
                  v-slot="{ ds }"
                  :ds-data="stockMoves"
                  
                >
                  <div class="row">
                    <div class="col-md-6 mb-3 mb-md-0">
                      <dataset-show :ds-show-entries="5" />
                    </div>
                    <!-- <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." :wait="300" />
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]" @click="click($event, index)">
                                {{ th.name }} <i class="gg-select float-right"></i>
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row, rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer" >
                                <td scope="row">{{ rowIndex + 1 }}</td>
                                <td scope="row">{{ row.ref}}</td>
                                <td scope="row">{{ row.createdBy.fullName}}</td>
                                <td scope="row">{{ row.op_date}}</td>
                                <td scope="row">
                                  <label v-if="row.fromLocation != 'N/A'" class="badge bg-primary">{{ row.fromLocation.name }}</label>
                                  <label v-else class="badge bg-primary">{{ row.externalFromLocation }}</label>
                                </td>
                                <td scope="row"><label class="badge bg-primary">{{ row.destLocation.name }}</label></td>
                                <td v-html="row.status.html"></td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-info class="mb-2 mb-md-0" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="mt-3">
            <productForm :selectedProduct="selectedRows[0]" :opType="opType" @refreshStockMovesTable="up = $event"/>
        </div>
        
      </div>
    </div>
</template>
